@mixin EmbedStolzl( $path, $weight: normal, $style: normal ) {
    @font-face {
      $f: "#{$path}.";
      $name: stolzl;
      font-family: 'Stolzl';
      src: url("#{$f}otf");
      font-weight: $weight;
      font-style: $style;
      font-display: swap;
    }
  }
  @include EmbedStolzl("fonts/Stolzl/stolzl_regular", 400, normal);
  @include EmbedStolzl("fonts/Stolzl/stolzl_bold", 700, normal);

  
  @mixin stolzl( $weight: normal, $style: normal ) {
    font-family: "Stolzl";
    font-style: $style;
    font-weight: $weight;
  }