@import './colors';
@import './fonts/index.scss';
@import './margins.scss';
@import './circles.scss';

body {
  background: $colorBackground;
  position: relative;
  padding: 15px;
  font-family: Stolzl;
  font-weight: 400;
  color: $colorPrimary;
  min-height: 120vh;
  overflow-x: hidden;
}

.body-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -20vh;
  min-height: 100vh;
  @media screen and (max-width: 992px) {
    margin-top: 0;
    display: block;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  img {
    max-width: 100%;
  }
}

.title {
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  width: max-content;
  margin: auto;
  padding-top: 72px;
  margin-top: 40px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding-top: 0px;
  }
  @media screen and (max-width: 500px) {
    font-size: 48px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 20px;

}

.border-bottom {
  width: 100%;
  border-bottom: 1px solid #000;
  padding-top: 154px;
}

.title-2 {
  font-size: 17px;
}

.text {
  font-size: 17px;
}

.link {
  display: block;
  color: $colorPrimary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ladder {
  position: absolute;
  left: -64px;
  top: 34px;
  z-index: -2;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.screwdriver {
  position: absolute;
  left: 14%;
  bottom: -5px;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.hemlet {
  position: absolute;
  left: 205px;
  bottom: 41px;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.znak {
  position: absolute;
  left: 277px;
  top: -8px;
  z-index: -1;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.spade {
  position: absolute;
  right: 114px;
  bottom: -10px;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.barrier {
  position: absolute;
  right: -42px;
  top: -10px;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.rabbit {
  font-size: 120%;
  width: 5em;
  height: 3em;
  background: #ffffff;
  border-radius: 70% 90% 60% 50%;
  position: absolute;
  top: 5em;
  left: 1em;
  z-index: 1;
}

.tail {
  position: absolute;
  width: 1em;
  height: 1em;
  background: white;
  border-radius: 100%;
  top: 0.5em;
  left: -0.3em;
  box-shadow:
    4em 0.4em 0 -0.35em #3f3334,
    /* eye */
    0.5em 1em 0 white,
    /* back foot */
    4em 1em 0 -0.3em white,
    /* front foot */
    4em 1em 0 -0.3em white,
    4em 1em 0 -0.4em white;
}

.ear {
  position: absolute;
  width: 0.75em;
  height: 2em;
  background: white;
  border-radius: 50% 100% 0 0;
  transform: rotate(-30deg);
  right: 1em;
  top: -1em;
  border-top: 1px solid #f7f5f4;
  border-left: 1px solid #f7f5f4;
  box-shadow: -0.5em 0em 0 -0.1em white;

}

/* Animacja skoku */
@keyframes hop {
  20% {
    transform: rotate(-10deg) translate(1em, -2em);
    box-shadow: -0.2em 3em 0 -1em #b78e81;
  }

  40% {
    transform: rotate(10deg) translate(3em, -4em);
    box-shadow: -0.2em 3.25em 0 -1.1em #b78e81;
  }

  60% {
    transform: rotate(15deg) translate(4em, -1.5em);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }

  100% {
    transform: rotate(0deg) translate(4em, 0);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }
}

/* Animacja ogona */
@keyframes kick {

  20%,
  50% {
    box-shadow:
      4em 0.4em 0 -0.35em #3f3334,
      0.5em 1.5em 0 white,
      4em 1.75em 0 -0.3em white,
      4em 1.75em 0 -0.3em white,
      4em 1.9em 0 -0.4em white;
  }

  40% {
    box-shadow:
      4em 0.4em 0 -0.35em #3f3334,
      0.5em 2em 0 white,
      4em 1.75em 0 -0.3em white,
      4.2em 1.75em 0 -0.2em white,
      4.4em 1.9em 0 -0.2em white;
  }

  100% {
    box-shadow:
      4em 0.4em 0 -0.35em #3f3334,
      /* eye */
      0.5em 1em 0 white,
      /* back foot */
      4em 1em 0 -0.3em white,
      /* front foot */
      4em 1em 0 -0.3em white,
      4em 1em 0 -0.4em white;
  }
}

.hop-animation {
  animation: hop 1.2s linear;
  /* 'forwards' zatrzymuje animację na ostatniej klatce */
  animation-fill-mode: forwards;
}

.hop-animation-2 {
  animation: hop2 1.4s linear;
  animation-fill-mode: forwards;
}

.hop-animation-3 {
  animation: hop3 1.4s linear;
  animation-fill-mode: forwards;
}

.hop-animation-4 {
  animation: hop4 1.2s linear;
  animation-fill-mode: forwards;
}

.kick-animation {
  animation: kick 1.6s linear;
  animation-fill-mode: forwards;
}

/* Animacja drugiego skoku */
@keyframes hop2 {
  20% {
    transform: rotate(-10deg) translate(7em, -2em);
    box-shadow: -0.2em 3em 0 -1em #b78e81;
  }

  40% {
    transform: rotate(10deg) translate(9em, -5em);
    box-shadow: -0.2em 3.25em 0 -1.1em #b78e81;
  }

  60% {
    transform: rotate(15deg) translate(10em, -3em);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }

  100% {
    transform: rotate(0deg) translate(10em, 0);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }
}

/* Nowa klasa animacji drugiego skoku */


/* Animacja trzeciego skoku */
@keyframes hop3 {
  20% {
    transform: rotate(-10deg) translate(13em, -2em);
    box-shadow: -0.2em 3em 0 -1em #b78e81;
  }

  40% {
    transform: rotate(10deg) translate(14em, -5em);
    box-shadow: -0.2em 3.25em 0 -1.1em #b78e81;
  }

  60% {
    transform: rotate(15deg) translate(15em, -4.5em);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }

  100% {
    transform: rotate(0deg) translate(15em, 0);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }
}

@keyframes hop4 {
  0% {
    transform-origin: 100% 100%;
    /* Ustawienie punktu odniesienia na dolną krawędź */
  }

  20% {
    transform: rotate(-10deg) translate(19em, -2em);
    box-shadow: -0.2em 3em 0 -1em #b78e81;
  }

  40% {
    transform: rotate(10deg) translate(21em, -4em);
    box-shadow: none;
  }

  // 50% {
  //   transform: rotate(10deg) translate(22em, -4em);
  //   box-shadow: none;
  // }
  // 60% {
  //   transform: rotate(10deg) translate(22em, -3.5em);
  //   box-shadow: none;
  // }
  100% {
    transform: rotate(20deg) translate(22em, 2.2em) scale(0);
    box-shadow: none;
  }
}

/* Klasy dla trzeciego i czwartego skoku */
.animation-wrap {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  &-div {
    position: absolute;
    left: 50%;
    transform: translateX(-66%);
    width: 730px;
    @media screen and (max-width: 992px) {
      left: 0;
      transform: translateX(0);
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      left: 50%;
      transform: translateX(-66%);
      width: 150%;
    }
    @media screen and (max-width: 399px) {
      left: 50%;
      transform: translateX(-66%);
      width: 160%;
    }
  }

}

.hole {
  position: absolute;
  top: 7em;
  /* Dostosuj pozycję pionową dziury */
  left: 25.2em;

  /* Pozycjonowanie w poziomie - dostosuj zgodnie z ruchem królika */
  // z-index: 2; /* Ustawiamy dziurę nad królikiem */
  svg {
    position: relative;

    .circle-top {
      position: relative;
      z-index: 0;

    }

    .circle-bottom {
      position: relative;
      z-index: 2;

    }
  }

  &-bottom {
    position: absolute;
    height: 150px;
    background: $colorBackground;
    z-index: 1;
    top: 47px;
    width: 100%;
  }
}