.circles-1 {

    .circle {
        position: absolute;
        z-index: -2;
        left: 20px;
        animation: rotate 35s infinite linear;

        &-1 {
            top: 144px;
            left: 20px;
        }

        &-2 {
            left: -40px;
            top: 0;
            width: 164px;
            height: 164px;
            animation-duration: 25s;
        }

        &-3 {
            left: 139px;
            top: 48px;
            animation-duration: 18s;
        }


        &-4 {
            left: 239px;
            top: 51px;
            animation-duration: 18s;
        }

        &-5 {
            left: 323px;
            top: 10px
        }

        &-6 {
            left: 318px;
            top: 96px;
        }
    }

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.circles-2 {
    @media screen and (max-width: 992px) {
        display: none;
    }

    .circle {
        position: absolute;
        z-index: -2;
        animation: rotate 35s infinite linear;

        &-1 {
            bottom: calc(20vh + 70px);
            right: 202px;
            animation-duration: 35s;
        }

        &-2 {
            bottom: calc(20vh + 193px);
            right: 161px;
            animation-duration: 35s;
        }

        &-3 {
            right: 20px;
            bottom: calc(20vh + 60px);
            animation-duration: 25s;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}